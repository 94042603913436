import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/dont-panic-poster.jpg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1987 Mexican horror movie, Don't Panic"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 19</h1>
                    <h2>Don't Panic</h2>
                    <h3>September 25, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <p><b>Director:</b> Ruben Galindo Jr // <b>Starring:</b> Jon Michael Bischoff &amp; Gabriella Hassel</p>
                        <div className="player">
                        <iframe src="https://podcasters.spotify.com/pod/show/bring-me-the-axe/embed/episodes/19-Dont-Panic-Bonus-e29ol96" height="102px" width="400px" frameborder="0" scrolling="no" title="Bring Me The Axe horror podcast episode 19, Don't Panic"></iframe>
                        </div>
                        <p><Link to="/transcripts/dont-panic">Read the episode transcript</Link></p>
                        <p>In honor of Hispanic Heritage Month, Bryan and Dave take a trip to Mexico City to celebrate their favorite Ruben Galindo Jr. movie, Don't Panic. It's a blatant a cash-in on the success of Evil Dead and A Nightmare on Elm Street that has so many bizarre qualities to it that you can't help but love it. It's about the love of a grown-ass man and his dinosaur pajamas. It's about the tension between best friends, who might also be enemies? It's about the love shared between two people who no chemistry whatsoever.</p>
                        <p>Trailer: <OutboundLink href="https://www.youtube.com/watch?v=MeE1slSpJ_Q" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=MeE1slSpJ_Q</OutboundLink>⁠</p>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/halloween-2018">Next Episode</Link></li>
                        <li><Link to="/episodes/the-peanut-butter-solution">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)